var origin = window.origin || window.location.origin;
//angular.module("heliApp").constant("REQUEST_URL", "http://ec2-3-94-107-198.compute-1.amazonaws.com/api/v1/")
//angular.module("heliApp").constant("REQUEST_URL", origin + "/api/v1/")
angular.module("heliApp").constant("REQUEST_URL", "https://staginglara.heli.life/api/v1/")
.constant("GOOGLE_KEY", "175241274078-c1r4njl89vr9ibkj4j4ucluou63malv1.apps.googleusercontent.com")
.constant("LINKEDIN_KEY", "81abcxrgp4kfgz")
//.constant("FB_KEY", {"appId":"1422669461189430","apiVersion":"v2.8"})
.constant('FB_KEY', { 'appId': '318457688551602', 'apiVersion': 'v2.6' })
//.constant("STRIPE_KEY", "pk_test_GPfron4l9nXIpe6kG7mlis4O")
// Flywire constants
.constant('FLYWIRE_PORTAL_CODE', 'HEU') // The 3 letter code of Flywire payment portal
.constant('FLYWIRE_ENVIRONMENT', 'demo') // Environment: 'demo' or 'production'
.constant('FLYWIRE_SHARED_SECRET', '1234567890') // The 32 letter shared secret for v$

.constant("STRIPE_KEY", "pk_test_iTzakvSQHRGILhyOsRyKJT29")
.constant("GOOGLE_API_KEY","AIzaSyAuIYqyr0_-5X64rInuOy7myi-0i25DrB8")
.constant('AIRPORT_KEY', 'b0f2ddb9famsha591d10030027e4p1c801cjsna9619216aca2')
// Plaid constants
    .constant('PLAID_ENVIRONMENT', 'sandbox')
    .constant('PLAID_PUBLIC_KEY', '04e183d46e744c6d08532f0c0fe12c')
    .constant('PLAID_SECRET', '3c05b71d0fc2d6a29d22bd86c7719c')
    .constant('PLAID_CLIENT_ID', '5bcf5d02e188cf0013527cba')
    .constant('PLAID_WEBHOOK_URL', 'https://heli-staging.kiwireader.com/')
.constant("GOOGLE_API_KEY_WEB_FONTS", "AIzaSyDJmOfFa1xHQo6gkANxycq2tgM35I05JV8")
.constant('IPAPI_KEY', '')
.constant('CURRENT_HOST', ['stagingweb.heli.life'])
.constant('ENABLE_CREDITS', true)
;
