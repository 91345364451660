import { Component, Input, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-activity-stories-banner',
  templateUrl: '/ng-app/src/app/components/activity-categories/activity-stories-banner/activity-stories-banner.component.html',
  styleUrls: ['../../../../../../ng-app/src/app/components/activity-categories/activity-stories-banner/activity-stories-banner.component.css']
})
export class ActivityStoriesBannerComponent implements OnInit {

  @Input() selectedDestinationStories: any;
  @Input() selectedActivity: any = {};
  @Input() destinationCategory: boolean = false;

  categoryStoriesData:any = [];
  requestNameActivity: string;

  constructor(
    @Inject(HttpClient) private httpClient: HttpClient
  ) { }

  ngOnInit(): void {
    if (this.selectedDestinationStories) {
      this.setDestinationStoriesData(this.selectedDestinationStories)
    } else {
      this.nameToCorrectFormat();
      this.getCategoryStories();
    }
  }

  setDestinationStoriesData(destinationStories) {
    destinationStories.forEach(story => {
      this.categoryStoriesData.push({
        story_img: story.img_src,
        sport_categories: story.tags,
        title: story.title,
        date: story.date,
        description: story.part_description,
        href: story.href
      })
    });
  }

  getStoryDate(data) {
    let date;
    date = new Date(data.substring(0, data.indexOf(' ')));
    let day = date.getDate();
    let month = date.toLocaleString('default', { month: 'long' });
    let year = date.getFullYear();
    return `${month} ${day}, ${year}`
  }

  getCategoryStories() {
    this.httpClient.get(`https://blog.heli.life/wp-json/api/v1/get-blog-content/${this.requestNameActivity}`).subscribe((response:any = []) => {
      response.forEach(element => {
        element['date'] = this.getStoryDate(element['date']);
      });
      this.setDestinationStoriesData(response);
    }, err => {
      console.log(err.message);
    })
  }

  nameToCorrectFormat() {
    switch (this.selectedActivity) {
      case 'ski':
        this.selectedActivity = 'Ski & Ride';
        this.requestNameActivity = 'ski-ride';
        break;
      case 'fish':
        this.requestNameActivity = 'fishing';
        break;
      case 'bike':
        this.requestNameActivity = 'mountain-bike';
        break;
      default:
        this.requestNameActivity = this.selectedActivity.toLowerCase();
        break;
    }
  }
}
