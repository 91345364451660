import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-adventures-list',
  templateUrl: '/ng-app/src/app/components/header/navbar/adventures-list/adventures-list.component.html',
  styleUrls: ['../../../../../../../ng-app/src/app/components/header/navbar/adventures-list/adventures-list.component.css']
})
export class AdventuresListComponent implements OnInit {

  @Input() adventures: any = [];
  @Input() goToSelectedCategory;

  premium_adventure = {
    title: "Premium",
    id: 100,
    image: 'https://heli-staging.s3.us-east-1.amazonaws.com/images/categories/menu/heli-black_600.jpg',
    sub_image: 'https://heli-staging.s3.us-east-1.amazonaws.com/images/heli-black-logo.svg',
    redirect_url: 'https://heli.black/',
  }
  constructor() { }

  ngOnInit(): void {
  }

}
