  /**
 * @ngdoc overview
 * @name heliApp
 * @description
 * # heliApp
 *
 * Environment Declaration of the application.
 */

export const environment = {
    REQUEST_URL: 'https://staginglara.heli.life/api/v1/' //heli api
};
