import { Component, Input, OnInit } from '@angular/core';
import { LoadAdventuresService } from '../../services/load-adventures/load-adventures.service';

@Component({
  selector: 'app-sort-filter',
  templateUrl: '/ng-app/src/app/components/sort-filter/sort-filter.component.html',
  styleUrls: ['../../../../../ng-app/src/app/components/sort-filter/sort-filter.component.css']
})
export class SortFilterComponent implements OnInit {

  @Input() page: string;

  constructor(
    public loadAdventures: LoadAdventuresService
  ) { }

  ngOnInit(): void { }

  sortAdventure(sortby) {
    this.loadAdventures.filter.sort = sortby;
    this.loadAdventures.filter.page = 1;
    this.loadAdventures.filter.back = 1;
    this.loadAdventures.updateAdventures();
  };

}