import { Component, OnInit, Input } from '@angular/core';
import { LoadAdventuresService } from '../../services/load-adventures/load-adventures.service';
import { UIRouter, UIRouterGlobals } from '@uirouter/core';


@Component({
  selector: 'app-sidebar-filter',
  templateUrl: '/ng-app/src/app/components/sidebar-filter/sidebar-filter.component.html',
  styleUrls: ['../../../../../ng-app/src/app/components/sidebar-filter/sidebar-filter.component.css']
})
export class SidebarFilterComponent implements OnInit {

  selectedAbility: any = [];
  selectedActivities: any = [];
  
  @Input('page') page: string = null;

  constructor(
    public loadAdventures: LoadAdventuresService,
    public router: UIRouter,
    public routerGlobals: UIRouterGlobals
  ) {
  }

  ngOnInit(): void {
    this.loadAdventures.getActivities();
  }

  changeCount(key, count) {
    if (this.loadAdventures.filter[key] === 0 && count < 0) { return; }
    this.loadAdventures.filter[key] = Number(this.loadAdventures.filter[key]) + Number(count);
  }

  removeFilterTag(tag, ifClearAll = null) {
    let index;
    switch (tag.type) {
      case 'activity':
        index = this.getIndex(this.loadAdventures.filter.activities, tag.name);
        this.loadAdventures.filter.activities[index].isChecked = false;
        this.selectedActivities = this.selectedActivities.splice(this.selectedActivities.indexOf(tag.name), 1);
        tag.name = '';
        break;
      case 'ability':
        index = this.getIndex(this.loadAdventures.filter.abilities, tag.name);
        this.loadAdventures.filter.abilities[index].isChecked = false;
        this.selectedAbility = this.selectedAbility.splice(this.selectedAbility.indexOf(tag.name), 1);
        tag.name = '';
        break;
      case 'days':
        this.loadAdventures.filter.days = 0;
        tag.name = '';
        break;
      case 'people':
        this.loadAdventures.filter.people = 0;
        tag.name = '';
        break;
      case 'price':
        this.loadAdventures.filter.min_price = '';
        this.loadAdventures.filter.max_price = '';
        tag.name = '';
        break;
      default :
        break;
    }
    this.loadAdventures.filtersApplied.splice(this.loadAdventures.appliedFilters.indexOf(tag), 1);
    this.loadAdventures.appliedFilters = this.loadAdventures.filtersApplied.slice();
    if (!ifClearAll) {
      this.loadAdventures.navigateWithFilterParams(this.page);
      this.loadAdventures.updateAdventures();
    }
  };

  removeQueryfromUrl(tag) {
    this.routerGlobals.params[tag.name] = null;
  }

  clearAllFilters() {
    this.loadAdventures.appliedFilters.map((filter) => {
      this.removeFilterTag(filter, true);
    });
    this.loadAdventures.navigateWithFilterParams(this.page)
    this.loadAdventures.updateAdventures();
  };

  checkAbilityChange(ability) {
    if (ability.isChecked) {
      this.loadAdventures.filtersApplied.push({name: ability.name, type: 'ability'});
      this.selectedAbility.push(ability.name);
    } else {
      const index = this.getIndex(this.loadAdventures.filtersApplied, ability.name);
      this.loadAdventures.filtersApplied.splice(index, 1);
      this.selectedAbility.splice(this.selectedAbility.indexOf(ability.name), 1);
    }
  };

  checkActivityChange(activity) {
    if (activity.isChecked) {
      this.loadAdventures.filtersApplied.push({name: activity.name, type: 'activity'});
      this.selectedActivities.push(activity.name);
    } else {
      const index = this.getIndex(this.loadAdventures.filtersApplied, activity.name);
      this.loadAdventures.filtersApplied.splice(index, 1);
      this.selectedActivities.splice(this.selectedActivities.indexOf(activity.name), 1);
    }
  };

  getIndex(arr, name) {
    return arr.findIndex(elem => elem.sport_name === name || elem.name === name);
  }
}