import { Component, Input, OnInit, OnDestroy, Inject } from '@angular/core';
import { LoadAdventuresService } from '../../../services/load-adventures/load-adventures.service';
import { UIRouter, UIRouterGlobals } from '@uirouter/core';

interface IServerResponse {
  items: string[];
  total: number;
}

@Component({
  selector: 'app-activities-list',
  templateUrl: '/ng-app/src/app/components/activity-categories/activities-list/activities-list.component.html',
  styleUrls: ['../../../../../../ng-app/src/app/components/activity-categories/activities-list/activities-list.component.css']
})
export class ActivitiesListComponent implements OnInit {

  adventure: any;

  @Input() destinationPage: boolean = false;
  @Input() destinationAdventureLink: boolean = false;

  @Input() page: string;

  constructor(
    @Inject("$scope") private scopeJS: any,
    @Inject(UIRouter) public router: UIRouter,
    public routerGlobals: UIRouterGlobals,
    public loadAdventures: LoadAdventuresService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.loadAdventures.noDataFoundAfterFilter = false;
  }

  pageChanged(paginationPageNumber) {
    this.loadAdventures.filter.page = paginationPageNumber;
    this.loadAdventures.applyFilters(paginationPageNumber);
    this.loadAdventures.navigateWithFilterParams(this.page)
    this.scrollToListTop();
  }

  scrollToListTop(){
    const element = document.getElementById('activities-list');
    const headerOffset = 95;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }

  viewAllSlots(event, adventure) {
    this.adventure = adventure;
    this.scopeJS.showPopup('#slot');
    event.stopPropagation();
  };

  loadMore() {
    this.loadAdventures.filter.page += 1;
    this.loadAdventures.filter.back = 0;
    this.loadAdventures.updateAdventures(true, this.loadAdventures.filter.page);
  };

  getSeoFriendlyUrl(str) {
    return str?.replace(/\W+(?!$)/g, '-').toLowerCase();
  }

  setLocationLen(locationName) {
    let location = Array.isArray(locationName) ? locationName[0].split(',') : locationName.split(',');
    return location[location.length - 1];
  }
}
