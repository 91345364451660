import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partners',
  templateUrl: '/ng-app/src/app/components/home/partners/partners.component.html',
  styleUrls: ['../../../../../../ng-app/src/app/components/home/partners/partners.component.css']
})
export class PartnersComponent implements OnInit {

  slickHeliPartnerConfig = {
    enabled: true,
    autoplay: true,
    draggable: true,
    slidesToShow: 8,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  }

  partners = [
    {
      id: 1,
      link: 'https://firstdescents.org',
      logoSrc: 'images/partners-update/firstdescents.png'
    },
    {
      id: 2,
      link: 'https://www.surefoot.com',
      logoSrc: 'images/partners-update/surefoot.png'
    },
    {
      id: 3,
      link: 'https://northactionsports.com',
      logoSrc: 'images/partners-update/north.png'
    },
    {
      id: 4,
      link: 'https://www.mysticboarding.com',
      logoSrc: 'images/partners-update/mystic.png'
    },
    {
      id: 6,
      addClass: 'custom-width',
      link: 'https://www.norrona.com',
      logoSrc: 'images/partners-update/norrona.png'
    },
    {
      id: 7,
      link: 'https://www.thenorthface.com',
      logoSrc: 'images/partners-update/thenorthface.png'
    },
    {
      id: 8,
      link: 'https://ridecore.com/us',
      logoSrc: 'images/partners-update/core.png'
    },
    {
      id: 9,
      link: 'https://www.oakley.com',
      logoSrc: 'images/partners-update/oakley.png'
    },
    {
      id: 10,
      link: 'https://www.armadaskis.com',
      logoSrc: 'images/partners-update/armada.png'
    },
    {
      id: 11,
      link: 'https://arcteryx.com/us/en',
      logoSrc: 'images/partners-update/arcteryx.png'
    },
    {
      id: 12,
      link: 'https://www.smithoptics.com',
      logoSrc: 'images/partners-update/smith.png'
    },
    // {
    //   id: 1,
    //   link: 'https://www.backcountry.com/?avad=261981_f1900ff09&PubID=208237&PubName=heli.life.',
    //   logoSrc: 'images/partners-update/backcountry.png'
    // },
    // {
    //   id: 2,
    //   link: 'https://flylowgear.com/',
    //   logoSrc: 'images/partners-update/flylow.png'
    // },
    // {
    //   id: 3,
    //   link: 'http://www.helicat.org/',
    //   logoSrc: 'images/partners-update/helicat.png'
    // },
    // {
    //   id: 4,
    //   link: 'https://www.slingshotsports.com/',
    //   logoSrc: 'images/partners-update/slingshot.jpg'
    // },
    // {
    //   id: 5,
    //   link: 'https://opensnow.com/',
    //   logoSrc: 'images/partners-update/opensnow.png'
    // },
    // {
    //   id: 6,
    //   link: 'https://firstdescents.org/',
    //   logoSrc: 'images/partners-update/first-descents.png'
    // },
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
